.App {
  text-align: center;
  background: rgba(245, 175, 75, 0.1);
  height: 100vh;
  box-sizing: border-box;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
